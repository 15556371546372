<template>
  <div>
    <b-row class="match-height">
      <b-col xl="4" lg="4" md="6" sm="12">
        <ruffle-my-tickets :image="image" :tickets="tickets" />
      </b-col>
      <b-col xl="8" lg="8" md="6" sm="12">
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">{{ ruffle.title }}</b-card-title>
            </div>
            <div
              class="p-1 text-center"
              style="border: 2px solid #ff8c00; border-radius: 10px"
              v-if="winner"
            
            >
              <b-card-title class="font-weight-bolder text-warning">
                <b-img
                  :src="require('@/assets/images/illustration/crown.svg')"
                  style="width: 18px; height: 18px; margin-top: -8px"
                >
                </b-img>
                Raffle Winner</b-card-title
              >
              <h6 class="mb-0">{{winner}}</h6>
            </div>
          </b-card-header>
          <b-card-body>
                 <b-card-sub-title v-html="ruffle.decription"></b-card-sub-title>
            <b-tabs pills fill>
              <b-tab active>
                <template #title>
                  <span>Details</span>
                </template>
                <div class="text-center">
                  <ruffle-details :details="ruffle" />
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span>Participants</span>
                </template>
                <ruffle-participants :details="ruffle" />
              </b-tab>
            </b-tabs>
          </b-card-body>
          <footer class="mt-2">
            <b-button
              :class="
                haveCrowns
                  ? 'backgroundBtn mt-1'
                  : 'backgroundBtnNE mt-1'
              "
              pill
              :disabled="!buy"
              block
              @click="haveCrowns ? buyruffle() : null"
            >
              {{ haveCrowns ? "Buy Ticket" : "Not Enough Crowns " }}
            </b-button>
          </footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BTabs,
  BTab,
  BButton,
  BImg,
} from "bootstrap-vue";
import RuffleDetails from "@/views/elite/ruffle/RuffleDetails.vue";
import RuffleParticipants from "@/views/elite/ruffle/RuffleParticipants.vue";
import RuffleMyTickets from "@/views/elite/ruffle/RuffleMyTickets.vue";
import SellerService from "@/services/SellerService";
import UserService from "@/services/UserService";
export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTabs,
    BTab,
    RuffleDetails,
    RuffleMyTickets,
    RuffleParticipants,
    BButton,
    BImg,
  },
  data() {
    let ru = JSON.parse(localStorage.getItem("dataruffle"));
    return {
      ruffle: ru.item,
      image: ru.image,
      haveCrowns:ru.haveCrowns,
      tickets: [],
      exp: null,
      buy: null,
      winner: null,
    };
  },
  mounted() {
    this.getExpbyUser();
    this.getTickets();
    this.$root.$on("buy", (data) => {
      this.buy = data[0];
      this.winner = data[1];
    });
  },
  methods: {
    getExpbyUser: async function () {
      const ex = (
        await SellerService.getExpbyUser(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.exp = ex;
    },
    getTickets: async function () {
      const t = (
        await UserService.getTicketsbyItem(
          JSON.parse(localStorage.getItem("auth")).discordId,
          this.ruffle.id
        )
      ).data;
      this.tickets = t;
    },
    buyruffle: function () {
      this.$swal({
        title: "Are you sure?",
        text: "Purchasing this ticket you will be participating in the raffle",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Buy",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const d = (
            await SellerService.InsertBuyItem(
              JSON.parse(localStorage.getItem("auth")).discordId,
              this.ruffle
            )
          ).data;
          this.$swal({
            icon: "success",
            title: "Excellent!",
            text: "Your purchase has been successfully completed",

            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.dark-layout .nav-pills .nav-item .nav-link.active {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
}
.backgroundBtn {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
  border: transparent;
  padding: 18px 80px;
}
.backgroundBtnNE {
  background: #828282;
  border: transparent;
  border: transparent;
  padding: 18px 80px;
}
</style>
